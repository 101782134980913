<template>
  <v-dialog
    persistent
    v-model="dialogAddWarehouse"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">
        {{ texts.warehouses.dialogAdd.textTitle }}
      </p>
      <div class="border-bottom">
        <v-container
          class="pl-0 pr-0"
          fluid
        >
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="warehouseName"
                    type="text"
                    :label="texts.warehouses.dialogAdd.warehouseName"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="texts.warehouses.dialogAdd.warehouseName"
                    persistent-placeholder
                    maxLength="60"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="warehouseEmail"
                    type="text"
                    :label="texts.warehouses.dialogAdd.warehouseEmail"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="texts.warehouses.dialogAdd.warehouseEmail"
                    persistent-placeholder
                    maxLength="85"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="warehousePhoneNumber"
                    type="text"
                    :label="texts.warehouses.dialogAdd.warehousePhoneNumber"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouses.dialogAdd.warehousePhoneNumber
                    "
                    persistent-placeholder
                    @keypress="soloNumbers"
                    maxLength="13"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-text-field
                    v-model="reponsableWarehouse"
                    type="text"
                    :label="texts.warehouses.dialogAdd.reponsableWarehouse"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouses.dialogAdd.reponsableWarehouse
                    "
                    persistent-placeholder
                    maxLength="40"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <div class="content-fieldset">
                    <v-menu
                      ref="menu15"
                      v-model="time15"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseShippingLimitTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-if="isResponsive"
                          v-model="warehouseShippingLimitTime"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular input-hint-limite-diaria"
                          :label="texts.warehouses.dialogAdd.warehouseShippingLimitTime"
                          :placeholder="
                            texts.warehouses.dialogAdd
                              .warehouseShippingLimitTimePlaceholder
                          "
                          persistent-placeholder
                        >
                          <template slot="append-outer">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  color="blue"
                                >
                                  mdi-information
                                </v-icon>
                              </template>
                              <span>
                                {{
                                    selectLanguage == "sp"
                                      ? "Hora límite diaria para despachar cargas desde tu almacén."
                                      : "Daily time limit to send items from your warehouse."
                                  }}
                              </span>
                            </v-tooltip>
                          </template>
                          <!-- <template slot="label">
                            <span>
                              {{
                                
                              }}
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="blue"
                                    size="20px"
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <span>
                                  {{
                                    selectLanguage == "sp"
                                      ? "Hora límite diaria para despachar cargas desde tu almacén."
                                      : "Daily time limit to send items from your warehouse."
                                  }}
                                </span>
                              </v-tooltip>
                            </span>
                          </template> -->
                        </v-text-field>
                        <v-text-field
                          v-else
                          v-model="warehouseShippingLimitTime"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular input-hint-limite-diaria"
                          :placeholder="
                            texts.warehouses.dialogAdd
                              .warehouseShippingLimitTimePlaceholder
                          "
                          persistent-placeholder
                        >
                          <template slot="label">
                            <span>
                              {{
                                texts.warehouses.dialogAdd
                                  .warehouseShippingLimitTime
                              }}
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="blue"
                                    size="20px"
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <span>
                                  {{
                                    selectLanguage == "sp"
                                      ? "Hora límite diaria para despachar cargas desde tu almacén."
                                      : "Daily time limit to send items from your warehouse."
                                  }}
                                </span>
                              </v-tooltip>
                            </span>
                          </template>
                        </v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time15"
                        v-model="warehouseShippingLimitTime"
                        full-width
                        @click:minute="
                          $refs.menu15.save(warehouseShippingLimitTime)
                        "
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.warehouseShippingLimitTime }}</p>
                    <vue-timepicker
                      v-model="warehouseShippingLimitTime"
                      format="HH:mm"
                      readonly
                    /> -->
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <div class="content-input-map">
                    <p class="content-input-map-label mon-regular">
                      {{ texts.warehouses.dialogAdd.wahrehouseAddress }}
                    </p>
                    <GmapAutocomplete
                      :value="wahrehouseAddress"
                      @place_changed="setPlace"
                      :options="autocompleteOptions"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <v-text-field
                      v-model="wahrehouseAddressOptional"
                      type="text"
                      :label="
                        selectLanguage == 'sp'
                          ? 'Dirección específica'
                          : 'Specific address'
                      "
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular"
                      :placeholder="
                        selectLanguage == 'sp'
                          ? 'Apt, suite, edificio, piso'
                          : 'Apt, suite, building, floor'
                      "
                      persistent-placeholder
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="warehouseCity"
                    type="text"
                    :label="texts.warehouses.dialogAdd.warehouseCity"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="texts.warehouses.dialogAdd.warehouseCity"
                    persistent-placeholder
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="warehousePostalCode"
                    type="text"
                    :label="texts.warehouses.dialogAdd.warehousePostalCode"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouses.dialogAdd.warehousePostalCode
                    "
                    persistent-placeholder
                    maxLength="5"
                    @keypress="soloNumbers"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="warehouseCountry"
                    :items="itemsCountries"
                    item-text="sName"
                    item-value="sCountryId"
                    :label="texts.warehouses.dialogAdd.warehouseCountry"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="texts.warehouses.dialogAdd.warehouseCountry"
                    @input="selecState"
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="warehouseState"
                    :items="itemsStates"
                    item-text="sName"
                    item-value="sStateId"
                    :label="texts.warehouses.dialogAdd.warehouseState"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="texts.warehouses.dialogAdd.warehouseState"
                    @input="selecState"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <p class="text-title-schedule mon-bold mb-0">
                {{ texts.warehouses.dialogAdd.businesshours }}
              </p>
              <div class="warehouses-style bg-gray">
                <div class="display-flex align-items-center">
                  <v-switch
                    color="#151d27"
                    v-model="switchMonday"
                  />
                  <p class="text-schedule mon-medium">
                    {{ texts.warehouses.dialogAdd.schedules.textMonday }}
                  </p>
                  <v-spacer></v-spacer>
                  <!-- input 1 -->
                  <div
                    class="content-fieldset"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu1"
                      v-model="time1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseMondayFrom"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseMondayFrom"
                          :label="texts.warehouses.dialogAdd.schedules.textFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textFrom
                          "
                          persistent-placeholder
                          :disabled="!switchMonday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time1"
                        v-model="warehouseMondayFrom"
                        full-width
                        @click:minute="$refs.menu1.save(warehouseMondayFrom)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textFrom }}</p>
                <vue-timepicker
                  v-model="warehouseMondayFrom"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchMonday"
                /> -->
                  </div>
                  <!-- input 2 -->
                  <div
                    class="content-fieldset ml-2"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu2"
                      v-model="time2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseMondayTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseMondayTo"
                          :label="texts.warehouses.dialogAdd.schedules.textTo"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textTo
                          "
                          persistent-placeholder
                          :disabled="!switchMonday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time2"
                        v-model="warehouseMondayTo"
                        full-width
                        @click:minute="$refs.menu2.save(warehouseMondayTo)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textTo }}</p>
                <vue-timepicker
                  v-model="warehouseMondayTo"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchMonday"
                /> -->
                  </div>
                </div>
                <div class="display-flex align-items-center">
                  <v-switch
                    color="#151d27"
                    v-model="switchTuesday"
                  />
                  <p class="text-schedule mon-medium">
                    {{ texts.warehouses.dialogAdd.schedules.textTuesday }}
                  </p>
                  <v-spacer></v-spacer>
                  <!-- input 1 -->
                  <div
                    class="content-fieldset"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu3"
                      v-model="time3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseTuesdayFrom"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseTuesdayFrom"
                          :label="texts.warehouses.dialogAdd.schedules.textFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textFrom
                          "
                          persistent-placeholder
                          :disabled="!switchTuesday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time3"
                        v-model="warehouseTuesdayFrom"
                        full-width
                        @click:minute="$refs.menu3.save(warehouseTuesdayFrom)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textFrom }}</p>
                <vue-timepicker
                  v-model="warehouseTuesdayFrom"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchTuesday"
                /> -->
                  </div>
                  <!-- input 2 -->
                  <div
                    class="content-fieldset ml-2"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu4"
                      v-model="time4"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseTuesdayTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseTuesdayTo"
                          :label="texts.warehouses.dialogAdd.schedules.textTo"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textTo
                          "
                          persistent-placeholder
                          :disabled="!switchTuesday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time4"
                        v-model="warehouseTuesdayTo"
                        full-width
                        @click:minute="$refs.menu4.save(warehouseTuesdayTo)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textTo }}</p>
                <vue-timepicker
                  v-model="warehouseTuesdayTo"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchTuesday"
                /> -->
                  </div>
                </div>
                <div class="display-flex align-items-center">
                  <v-switch
                    color="#151d27"
                    v-model="switchWednesday"
                  />
                  <p class="text-schedule mon-medium">
                    {{ texts.warehouses.dialogAdd.schedules.textWednesday }}
                  </p>
                  <v-spacer></v-spacer>
                  <div
                    class="content-fieldset"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu5"
                      v-model="time5"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseWednesdayFrom"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseWednesdayFrom"
                          :label="texts.warehouses.dialogAdd.schedules.textFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textFrom
                          "
                          persistent-placeholder
                          :disabled="!switchWednesday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time5"
                        v-model="warehouseWednesdayFrom"
                        full-width
                        @click:minute="$refs.menu5.save(warehouseWednesdayFrom)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textFrom }}</p>
                <vue-timepicker
                  v-model="warehouseWednesdayFrom"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchWednesday"
                /> -->
                  </div>
                  <!-- input 2 -->
                  <div
                    class="content-fieldset ml-2"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu6"
                      v-model="time6"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseWednesdayTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseWednesdayTo"
                          :label="texts.warehouses.dialogAdd.schedules.textTo"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textTo
                          "
                          persistent-placeholder
                          :disabled="!switchWednesday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time6"
                        v-model="warehouseWednesdayTo"
                        full-width
                        @click:minute="$refs.menu6.save(warehouseWednesdayTo)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textTo }}</p>
                <vue-timepicker
                  v-model="warehouseWednesdayTo"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchWednesday"
                /> -->
                  </div>
                </div>
                <div class="display-flex align-items-center">
                  <v-switch
                    color="#151d27"
                    v-model="switchThursday"
                  />
                  <p class="text-schedule mon-medium">
                    {{ texts.warehouses.dialogAdd.schedules.textThursday }}
                  </p>
                  <v-spacer></v-spacer>
                  <div
                    class="content-fieldset"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu7"
                      v-model="time7"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseThursdayFrom"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseThursdayFrom"
                          :label="texts.warehouses.dialogAdd.schedules.textFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textFrom
                          "
                          persistent-placeholder
                          :disabled="!switchThursday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time7"
                        v-model="warehouseThursdayFrom"
                        full-width
                        @click:minute="$refs.menu7.save(warehouseThursdayFrom)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textFrom }}</p>
                <vue-timepicker
                  v-model="warehouseThursdayFrom"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchThursday"
                /> -->
                  </div>
                  <!-- input 2 -->
                  <div
                    class="content-fieldset ml-2"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu8"
                      v-model="time8"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseThursdayTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseThursdayTo"
                          :label="texts.warehouses.dialogAdd.schedules.textTo"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textTo
                          "
                          persistent-placeholder
                          :disabled="!switchThursday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time8"
                        v-model="warehouseThursdayTo"
                        full-width
                        @click:minute="$refs.menu8.save(warehouseThursdayTo)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textTo }}</p>
                <vue-timepicker
                  v-model="warehouseThursdayTo"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchThursday"
                /> -->
                  </div>
                </div>
                <div class="display-flex align-items-center">
                  <v-switch
                    color="#151d27"
                    v-model="switchFriday"
                  />
                  <p class="text-schedule mon-medium">
                    {{ texts.warehouses.dialogAdd.schedules.textFriday }}
                  </p>
                  <v-spacer></v-spacer>
                  <div
                    class="content-fieldset"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu9"
                      v-model="time9"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseFridayFrom"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseFridayFrom"
                          :label="texts.warehouses.dialogAdd.schedules.textFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textFrom
                          "
                          persistent-placeholder
                          :disabled="!switchFriday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time9"
                        v-model="warehouseFridayFrom"
                        full-width
                        @click:minute="$refs.menu9.save(warehouseFridayFrom)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textFrom }}</p>
                <vue-timepicker
                  v-model="warehouseFridayFrom"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchFriday"
                /> -->
                  </div>
                  <!-- input 2 -->
                  <div
                    class="content-fieldset ml-2"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu10"
                      v-model="time10"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseFridayTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseFridayTo"
                          :label="texts.warehouses.dialogAdd.schedules.textTo"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textTo
                          "
                          persistent-placeholder
                          :disabled="!switchFriday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time10"
                        v-model="warehouseFridayTo"
                        full-width
                        @click:minute="$refs.menu10.save(warehouseFridayTo)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textTo }}</p>
                <vue-timepicker
                  v-model="warehouseFridayTo"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchFriday"
                /> -->
                  </div>
                </div>
                <div class="display-flex align-items-center">
                  <v-switch
                    color="#151d27"
                    v-model="switchSaturday"
                  />
                  <p class="text-schedule mon-medium">
                    {{ texts.warehouses.dialogAdd.schedules.textSaturday }}
                  </p>
                  <v-spacer></v-spacer>
                  <div
                    class="content-fieldset"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu11"
                      v-model="time11"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseSaturdayFrom"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseSaturdayFrom"
                          :label="texts.warehouses.dialogAdd.schedules.textFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textFrom
                          "
                          persistent-placeholder
                          :disabled="!switchSaturday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time11"
                        v-model="warehouseSaturdayFrom"
                        full-width
                        @click:minute="$refs.menu11.save(warehouseSaturdayFrom)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textFrom }}</p>
                <vue-timepicker
                  v-model="warehouseSaturdayFrom"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchSaturday"
                /> -->
                  </div>
                  <!-- input 2 -->
                  <div
                    class="content-fieldset ml-2"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu12"
                      v-model="time12"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseSaturdayTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseSaturdayTo"
                          :label="texts.warehouses.dialogAdd.schedules.textTo"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textTo
                          "
                          persistent-placeholder
                          :disabled="!switchSaturday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time12"
                        v-model="warehouseSaturdayTo"
                        full-width
                        @click:minute="$refs.menu12.save(warehouseSaturdayTo)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textTo }}</p>
                <vue-timepicker
                  v-model="warehouseSaturdayTo"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchSaturday"
                /> -->
                  </div>
                </div>
                <div class="display-flex align-items-center">
                  <v-switch
                    color="#151d27"
                    v-model="switchSunday"
                  />
                  <p class="text-schedule mon-medium">
                    {{ texts.warehouses.dialogAdd.schedules.textSunday }}
                  </p>
                  <v-spacer></v-spacer>
                  <div
                    class="content-fieldset"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu13"
                      v-model="time13"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseSundayFrom"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseSundayFrom"
                          :label="texts.warehouses.dialogAdd.schedules.textFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textFrom
                          "
                          persistent-placeholder
                          :disabled="!switchSunday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time13"
                        v-model="warehouseSundayFrom"
                        full-width
                        @click:minute="$refs.menu13.save(warehouseSundayFrom)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textFrom }}</p>
                <vue-timepicker
                  v-model="warehouseSundayFrom"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchSunday"
                /> -->
                  </div>
                  <!-- input 2 -->
                  <div
                    class="content-fieldset ml-2"
                    style="width: 75px"
                  >
                    <v-menu
                      ref="menu14"
                      v-model="time14"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="warehouseSundayTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="warehouseSundayTo"
                          :label="texts.warehouses.dialogAdd.schedules.textTo"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          color="#000000"
                          class="global-inputs mon-regular"
                          :placeholder="
                            texts.warehouses.dialogAdd.schedules.textTo
                          "
                          persistent-placeholder
                          :disabled="!switchSunday"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="time14"
                        v-model="warehouseSundayTo"
                        full-width
                        @click:minute="$refs.menu14.save(warehouseSundayTo)"
                        format="24hr"
                        header-color="#151d27"
                        color="#151d27"
                      ></v-time-picker>
                    </v-menu>
                    <!-- <p>{{ texts.warehouses.dialogAdd.schedules.textTo }}</p>
                <vue-timepicker
                  v-model="warehouseSundayTo"
                  format="HH:mm"
                  placeholder="00:00"
                  readonly
                  :disabled="!switchSunday"
                /> -->
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div>
                <GmapMap
                  :center="center"
                  :zoom="4"
                  :options="mapOptions"
                  style="width: 100%; height: 360px"
                >
                  <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center = m.position"
                  />
                </GmapMap>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        v-if="isResponsive"
        class=""
      >
        <v-btn
          @click="addWarehouse"
          class="button-primary mon-bold mt-5"
          elevation="0"
          :loading="bLoading"
          style="width: 100%;"
        >
          {{ texts.warehouses.dialogAdd.buttonAdd }}
        </v-btn>
        <v-btn
          class="button-secondary mon-regular mt-5"
          elevation="0"
          @click="closeDialog"
          style="width: 100%;"
        >
          {{ texts.warehouses.dialogAdd.buttonClose }}
        </v-btn>
      </div>
      <div
        v-else
        class="display-flex align-items-center justify-content-flex-end mt-2"
      >
        <v-btn
          class="button-secondary mon-regular"
          elevation="0"
          @click="closeDialog"
        >
          {{ texts.warehouses.dialogAdd.buttonClose }}
        </v-btn>
        <v-btn
          @click="addWarehouse"
          class="button-primary mon-bold ml-2"
          elevation="0"
          :loading="bLoading"
        >
          {{ texts.warehouses.dialogAdd.buttonAdd }}
        </v-btn>
        <!-- :disabled="!validateForm" -->
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "WarehousesDialogAddLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      itemsCountries: [],
      itemsStates: [],
      screenWidth: 0,
      bLoading: false,
      warehouseName: "",
      wahrehouseAddress: "",
      wahrehouseAddressOptional: "",
      warehouseCountry: "",
      warehouseState: "",
      warehouseCity: "",
      warehousePostalCode: "",
      warehousePhoneNumber: "",
      warehouseEmail: "",
      reponsableWarehouse: "",
      warehouseLatitude: "",
      warehouseLongitude: "",
      warehouseShippingLimitTime: "",
      // --- Schedules --- //
      switchMonday: false,
      warehouseMondayFrom: "",
      warehouseMondayTo: "",
      switchTuesday: false,
      warehouseTuesdayFrom: "",
      warehouseTuesdayTo: "",
      switchWednesday: false,
      warehouseWednesdayFrom: "",
      warehouseWednesdayTo: "",
      switchThursday: false,
      warehouseThursdayFrom: "",
      warehouseThursdayTo: "",
      switchFriday: false,
      warehouseFridayFrom: "",
      warehouseFridayTo: "",
      switchSaturday: false,
      warehouseSaturdayFrom: "",
      warehouseSaturdayTo: "",
      switchSunday: false,
      warehouseSundayFrom: "",
      warehouseSundayTo: "",
      // google maps
      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      oPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 3,
      },
      autocompleteOptions: {
        componentRestrictions: {
          country: ["us", "mx"],
        },
      },
      // time
      time1: null,
      time2: null,
      time3: null,
      time4: null,
      time5: null,
      time6: null,
      time7: null,
      time8: null,
      time9: null,
      time10: null,
      time11: null,
      time12: null,
      time13: null,
      time14: null,
      time15: null,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getCountries();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setPlace: function (place) {
      this.warehouseCity = "";
      this.warehousePostalCode = "";

      // var dom = document.createElement("div");

      // dom.innerHTML = place.adr_address;

      // let postal_code_html = dom.getElementsByClassName("postal-code"),
      //   locality_html = dom.getElementsByClassName("locality");

      // this.warehousePostalCode = postal_code_html[0].innerHTML;

      // this.warehouseCity = locality_html[0].innerHTML;

      let split_name = place.formatted_address.split(",");

      let name_address = split_name[0] + "," + split_name[1];

      this.wahrehouseAddress = name_address;
      this.currentPlace = place;
      this.oPlace = place;

      this.addMarker();
      this.exampleFill(place);
    },
    exampleFill: function (place) {
      if (place.address_components) {
        const addressComponents = place.address_components;
        let selectedCountry;
        const countryAddressComponent = addressComponents.find((adc) =>
          adc.types.includes("country")
        );

        if (countryAddressComponent.short_name === "MX") {
          this.warehouseCountry = "México";
          selectedCountry = this.itemsCountries.find(
            (c) => c.sName === "México" || c.sName === "Mexico"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
        if (countryAddressComponent.short_name === "US") {
          selectedCountry = this.itemsCountries.find(
            (c) =>
              c.sName === "Estados Unidos de América" ||
              c.sName === "United States of America"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
      }
    },
    addMarker: function () {
      this.markers = [];

      if (this.currentPlace) {
        this.warehouseLatitude = this.currentPlace.geometry.location.lat();
        this.warehouseLongitude = this.currentPlace.geometry.location.lng();

        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      }
    },
    addWarehouse: function () {
      if (this.validateswitchMonday()) {
        this.mixError(
          this.selectLanguage == "sp"
            ? "Por favor ingrese el horario requerido en el dia: Lunes "
            : "Monday schedule required"
        );
        return true;
      }
      if (this.validateswitchTuesday()) {
        this.mixError(
          this.selectLanguage == "sp"
            ? "Por favor ingrese el horario requerido en el dia: Martes "
            : "Tuesday schedule required"
        );
        return true;
      }
      if (this.validateswitchWednesday()) {
        this.mixError(
          this.selectLanguage == "sp"
            ? "Por favor ingrese el horario requerido en el dia: Miercoles "
            : "Wednesday schedule required"
        );
        return true;
      }
      if (this.validateswitchThursday()) {
        this.mixError(
          this.selectLanguage == "sp"
            ? "Por favor ingrese el horario requerido en el dia: Jueves "
            : "Thursday schedule required"
        );
        return true;
      }
      if (this.validateswitchFriday()) {
        this.mixError(
          this.selectLanguage == "sp"
            ? "Por favor ingrese el horario requerido en el dia: Viernes "
            : "Friday schedule required"
        );
        return true;
      }
      if (this.validateswitchSaturday()) {
        this.mixError(
          this.selectLanguage == "sp"
            ? "Por favor ingrese el horario requerido en el dia: Sábado "
            : "Saturday schedule required"
        );
        return true;
      }
      if (this.validateswitchSunday()) {
        this.mixError(
          this.selectLanguage == "sp"
            ? "Por favor ingrese el horario requerido en el dia: Domingo "
            : "Sunday schedule required"
        );
        return true;
      }
      // this.bLoading = true;

      const payload = {
        oWarehouse: {
          sName: this.warehouseName,
          sPhoneNumber: this.warehousePhoneNumber,
          sEmail: this.warehouseEmail,
          sDescription: "N/A",
          sContactName: this.reponsableWarehouse,
          tShippingLimitTime: this.warehouseShippingLimitTime + ":00",
        },
        oLocation: {
          sLocationAddress: this.wahrehouseAddress,
          sLocationOptionalAddress: this.wahrehouseAddressOptional,
          sLocationCity: this.warehouseCity,
          sLocationStateId: this.warehouseState,
          sLocationZipCode: this.warehousePostalCode,
          dLocationLatitude: this.warehouseLatitude,
          dLocationLongitude: this.warehouseLongitude,
        },
        oSchedules: {
          tMondayOpeningTime: !this.switchMonday
            ? null
            : this.warehouseMondayFrom + ":00",
          tMondayClosingTime: !this.switchMonday
            ? null
            : this.warehouseMondayTo + ":00",
          tTuesdayOpeningTime: !this.switchTuesday
            ? null
            : this.warehouseTuesdayFrom + ":00",
          tTuesdayClosingTime: !this.switchTuesday
            ? null
            : this.warehouseTuesdayTo + ":00",
          tWednesdayOpeningTime: !this.switchWednesday
            ? null
            : this.warehouseWednesdayFrom + ":00",
          tWednesdayClosingTime: !this.switchWednesday
            ? null
            : this.warehouseWednesdayTo + ":00",
          tThursdayOpeningTime: !this.switchThursday
            ? null
            : this.warehouseThursdayFrom + ":00",
          tThursdayClosingTime: !this.switchThursday
            ? null
            : this.warehouseThursdayTo + ":00",
          tFridayOpeningTime: !this.switchFriday
            ? null
            : this.warehouseFridayFrom + ":00",
          tFridayClosingTime: !this.switchFriday
            ? null
            : this.warehouseFridayTo + ":00",
          tSaturdayOpeningTime: !this.switchSaturday
            ? null
            : this.warehouseSaturdayFrom + ":00",
          tSaturdayClosingTime: !this.switchSaturday
            ? null
            : this.warehouseSaturdayTo + ":00",
          tSundayOpeningTime: !this.switchSunday
            ? null
            : this.warehouseSundayFrom + ":00",
          tSundayClosingTime: !this.switchSunday
            ? null
            : this.warehouseSundayTo + ":00",
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 100;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogAddWarehouse", false);
      this.warehouseName = "";
      this.wahrehouseAddress = "";
      this.wahrehouseAddressOptional = "";
      this.warehouseCountry = "";
      this.warehouseState = "";
      this.warehouseCity = "";
      this.warehousePostalCode = "";
      this.warehousePhoneNumber = "";
      this.warehouseEmail = "";
      this.reponsableWarehouse = "";
      this.warehouseLatitude = "";
      this.warehouseLongitude = "";
      this.warehouseShippingLimitTime = "";
      this.switchMonday = false;
      this.warehouseMondayFrom = "";
      this.warehouseMondayTo = "";
      this.switchTuesday = false;
      this.warehouseTuesdayFrom = "";
      this.warehouseTuesdayTo = "";
      this.switchWednesday = false;
      this.warehouseWednesdayFrom = "";
      this.warehouseWednesdayTo = "";
      this.switchThursday = false;
      this.warehouseThursdayFrom = "";
      this.warehouseThursdayTo = "";
      this.switchFriday = false;
      this.warehouseFridayFrom = "";
      this.warehouseFridayTo = "";
      this.switchSaturday = false;
      this.warehouseSaturdayFrom = "";
      this.warehouseSaturdayTo = "";
      this.switchSunday = false;
      this.warehouseSundayFrom = "";
      this.warehouseSundayTo = "";
    },
    getCountries: function () {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`)
        .then((response) => {
          this.itemsCountries = response.data.results;
          this.warehouseCountry = response.data.results[0].sCountryId;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function () {
      this.state = "";
      this.getStates();
    },
    getStates: function () {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.warehouseCountry}/states`
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          if (this.oPlace) {
            const addressComponents = this.oPlace.address_components;
            for (let i = 0; i < addressComponents.length; i++) {
              //administrative_area_level_1 es el type que usa google para los estados
              if (
                addressComponents[i].types.includes(
                  "administrative_area_level_1"
                )
              ) {
                let selectedState = this.itemsStates.find(
                  (s) =>
                    this.normalizeString(s.sName) ===
                    this.normalizeString(addressComponents[i].long_name)
                );
                this.warehouseState = selectedState.sStateId;
              }

              if (addressComponents[i].types.includes("postal_code")) {
                //codigo postal
                this.warehousePostalCode = addressComponents[i].long_name;
              }

              //administrative_area_level_1 es el type que usa google para la ciudad
              if (addressComponents[i].types.includes("locality")) {
                //ciudad
                this.warehouseCity = addressComponents[i].long_name;
              }
            }
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    normalizeString: function (str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    validateswitchMonday() {
      if (this.switchMonday) {
        if (this.warehouseMondayFrom !== "" && this.warehouseMondayTo == "") {
          return true;
        } else if (
          this.warehouseMondayTo !== "" &&
          this.warehouseMondayFrom == ""
        ) {
          return true;
        } else if (
          this.warehouseMondayFrom == "" &&
          this.warehouseMondayTo == ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateswitchTuesday() {
      if (this.switchTuesday) {
        if (this.warehouseTuesdayFrom !== "" && this.warehouseTuesdayTo == "") {
          return true;
        } else if (
          this.warehouseTuesdayTo !== "" &&
          this.warehouseTuesdayFrom == ""
        ) {
          return true;
        } else if (
          this.warehouseTuesdayFrom == "" &&
          this.warehouseTuesdayTo == ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateswitchWednesday() {
      if (this.switchWednesday) {
        if (
          this.warehouseWednesdayFrom !== "" &&
          this.warehouseWednesdayTo == ""
        ) {
          return true;
        } else if (
          this.warehouseWednesdayTo !== "" &&
          this.warehouseWednesdayFrom == ""
        ) {
          return true;
        } else if (
          this.warehouseWednesdayFrom == "" &&
          this.warehouseWednesdayTo == ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateswitchThursday() {
      if (this.switchThursday) {
        if (
          this.warehouseThursdayFrom !== "" &&
          this.warehouseThursdayTo == ""
        ) {
          return true;
        } else if (
          this.warehouseThursdayTo !== "" &&
          this.warehouseThursdayFrom == ""
        ) {
          return true;
        } else if (
          this.warehouseThursdayFrom == "" &&
          this.warehouseThursdayTo == ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateswitchFriday() {
      if (this.switchFriday) {
        if (this.warehouseFridayFrom !== "" && this.warehouseFridayTo == "") {
          return true;
        } else if (
          this.warehouseFridayTo !== "" &&
          this.warehouseFridayFrom == ""
        ) {
          return true;
        } else if (
          this.warehouseFridayFrom == "" &&
          this.warehouseFridayTo == ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateswitchSaturday() {
      if (this.switchSaturday) {
        if (
          this.warehouseSaturdayFrom !== "" &&
          this.warehouseSaturdayTo == ""
        ) {
          return true;
        } else if (
          this.warehouseSaturdayTo !== "" &&
          this.warehouseSaturdayFrom == ""
        ) {
          return true;
        } else if (
          this.warehouseSaturdayFrom == "" &&
          this.warehouseSaturdayTo == ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    validateswitchSunday() {
      if (this.switchSunday) {
        if (this.warehouseSundayFrom !== "" && this.warehouseSundayTo == "") {
          return true;
        } else if (
          this.warehouseSundayTo !== "" &&
          this.warehouseSundayFrom == ""
        ) {
          return true;
        } else if (
          this.warehouseSundayFrom == "" &&
          this.warehouseSundayTo == ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  computed: {
    // return create contact dialog state
    dialogAddWarehouse() {
      return this.$store.state.dialogAddWarehouse;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.warehouseName != "" &&
        this.wahrehouseAddress != "" &&
        this.warehouseCountry != "" &&
        this.warehouseState != "" &&
        this.warehouseCity != "" &&
        this.warehousePostalCode != "" &&
        this.warehousePhoneNumber != "" &&
        this.warehouseEmail != "" &&
        this.reponsableWarehouse != "" &&
        this.warehouseLatitude != "" &&
        this.warehouseLongitude != "" &&
        this.warehouseShippingLimitTime != ""
      );
    },
  },
  watch: {
    dialogAddWarehouse() {
      if (this.dialogAddWarehouse) {
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
      }
    },
    switchMonday() {
      if (!this.switchMonday) {
        this.warehouseMondayFrom = "";
        this.warehouseMondayTo = "";
      }
    },
    switchTuesday() {
      if (!this.switchTuesday) {
        this.warehouseTuesdayFrom = "";
        this.warehouseTuesdayTo = "";
      }
    },
    switchWednesday() {
      if (!this.switchWednesday) {
        this.warehouseWednesdayFrom = "";
        this.warehouseWednesdayTo = "";
      }
    },
    switchThursday() {
      if (!this.switchThursday) {
        this.warehouseThursdayFrom = "";
        this.warehouseThursdayTo = "";
      }
    },
    switchFriday() {
      if (!this.switchFriday) {
        this.warehouseFridayFrom = "";
        this.warehouseFridayTo = "";
      }
    },
    switchSaturday() {
      if (!this.switchSaturday) {
        this.warehouseSaturdayFrom = "";
        this.warehouseSaturdayTo = "";
      }
    },
    switchSunday() {
      if (!this.switchSunday) {
        this.warehouseSundayFrom = "";
        this.warehouseSundayTo = "";
      }
    },
  },
};
</script>

<style>
.input-schedule {
  width: 75px !important;
  flex: none !important;
}

.warehouses-style .v-text-field input:disabled {
  cursor: no-drop !important;
}
</style>

<style scoped>
.content-input-map-label {
  font-size: 10px;
  position: absolute;
  top: -8px;
  left: 12px;
  color: #00000090;
}

.content-input-map {
  position: relative;
}
.text-title-schedule {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #304458;
  opacity: 1;
  margin-bottom: 0px;
}

.content-input-map input {
  width: 100%;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 9px !important;
  font-size: 12px;
  font-family: "monserrat-regular", sans-serif !important;
  padding: 0px 15px;
  letter-spacing: 0px;
  color: #00000090;
}

.content-input-map input:focus-visible {
  outline: none;
}

.content-input-map input::-webkit-input-placeholder {
  color: #fff;
}

.content-input-map input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input:-ms-input-placeholder {
  color: #fff;
}

.warehouses-style .v-input--is-disabled:not(.v-input--is-readonly) {
  opacity: 0.5;
  cursor: no-drop !important;
  pointer-events: all !important;
}

.text-schedule {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.bg-gray {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
}

.border-bottom {
  border-bottom: 1px solid #cecece;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

.input-hint-limite-diaria input {
  background-color: red !important;
  width: 90%;
}
</style>